<template>
  <div :key="userId">
    <div style="position: relative;">
      <div style="display: flex;">
        <div
          v-if="isOnline != null"
          :class="`isonline online-${isOnline ? 'green' : 'red'}`"
        ></div>
        <v-badge avatar bordered overlap v-if="flag" style="margin-top:5px; ">
          <template v-slot:badge>
            <v-avatar>
              <v-img
                :src="
                  `https://bundle.slotbase.net/assets/flags/${flag.toLocaleLowerCase()}.png`
                "
              ></v-img>
            </v-avatar>
          </template>

          <v-avatar @click="getUserDetail" size="36">
            <v-img
              v-if="pictureUrl.startsWith('http')"
              :src="`${pictureUrl}`"
            />
            <v-img
              v-else-if="pictureUrl.startsWith('/')"
              :src="`https://bundle.slotbase.net${pictureUrl}`"
            />
            <v-img
              v-else
              :src="`https://bundle.slotbase.net/assets/Avatars/${pictureUrl}`"
            />
          </v-avatar>
        </v-badge>
        <v-avatar v-else @click="getUserDetail" size="36">
          <v-img v-if="pictureUrl.startsWith('http')" :src="`${pictureUrl}`" />
          <v-img
            v-else-if="pictureUrl.startsWith('/')"
            :src="`https://bundle.slotbase.net${pictureUrl}`"
          />
          <v-img
            v-else
            :src="`https://bundle.slotbase.net/assets/Avatars/${pictureUrl}`"
          />
        </v-avatar>
        <div style="margin-left:10px; ">
          {{ userId }}
          <div>
            <span style="color:yellow">{{ level }}</span>
            | {{ userName }}
          </div>
        </div>
      </div>
    </div>
    <UserDetailModalCard ref="detail" :userId="userId" />
  </div>
</template>

<script>
export default {
  components: {
    UserDetailModalCard: () =>
      import("@/components/Users/UserDetailModalCard.vue"),
  },
  name: "UserDetailCard",

  props: {
    userId: {
      type: String,
      description: "UserId",
    },
    pictureUrl: {
      type: String,
      description: "PictureUrl",
    },
    level: {
      type: Number,
      description: "Level",
    },
    userName: {
      type: String,
      description: "UserName",
    },
    flag: {
      type: String,
      description: "flag",
    },
    isOnline: {
      type: Boolean,
      default: null,
      description: "flag",
    },
  },
  methods: {
    getUserDetail() {
      // eslint-disable-next-line no-console
      console.log(this.$refs.detail);
      this.$refs.detail.setTab("userProfile");
    },
  },
};
</script>
<style>
.isonline {
  border-radius: 25px;
  position: absolute;
  left: 5px;
  bottom: -1px;
  height: 10px;
  width: 10px;
  z-index: 1;
}
.online-green {
  background: lime;
}
.online-red {
  background: red;
}
</style>
