import { render, staticRenderFns } from "./UserDetailCard.vue?vue&type=template&id=136ab1de&"
import script from "./UserDetailCard.vue?vue&type=script&lang=js&"
export * from "./UserDetailCard.vue?vue&type=script&lang=js&"
import style0 from "./UserDetailCard.vue?vue&type=style&index=0&id=136ab1de&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports